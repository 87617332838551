<template>
  <v-app>
    <router-view />
  </v-app>
</template>
<script>
import { Preferences } from "@capacitor/preferences";
export default {
  name: "AcceleratorLayout",
  created() {
    console.log("AcceleratorLayout created");
    // I had made temp connection data for testing, this is for old connection test, need to remove later
    // var tempConnection = [
    //   {
    //     tabName: "SageCRM",
    //     tabCaption: "Sage CRM",
    //     tabAction: "summary",
    //     enabled: true,
    //     provider: "SystemContainer",
    //     username: "dolanw",
    //     password: "",
    //     dataurl: "https://dev2.crmtogether.com/CRM2021r2/",
    //     connectionClass: "MyTaskPaneEntityDataSageCRMAcceleratorClass",
    //     isCoreUIDriver: false,
    //     tabClass: "sagecrm",
    //     tabDescriptor: "",
    //     title: "title NOTSET (AppConnection)",
    //     details: "details NOTSET (AppConnection)",
    //     light: {
    //       primary: "#00815d",
    //       secondary: "#F3F2F1",
    //       accent: "#82B1FF",
    //       error: "#FF5252",
    //       info: "#2196F3",
    //       success: "#4CAF50",
    //       warning: "#FFC107",
    //     },
    //     dark: {
    //       primary: "#00815d",
    //       secondary: "#F3F2F1",
    //       accent: "#82B1FF",
    //       error: "#FF5252",
    //       info: "#2196F3",
    //       success: "#4CAF50",
    //       warning: "#FFC107",
    //     },
    //     rememberMe: true,
    //     connectionType: "Accelerator",
    //     userFlag: "new",
    //     themeName: "Sage",
    //   },
    // ];
    // localStorage.setItem("_cap_connections", JSON.stringify(tempConnection));
    if (localStorage.getItem("_cap_connections") != null) {
      Preferences.set({
        key: "connections",
        value: localStorage.getItem("_cap_connections"),
      });
      localStorage.removeItem("_cap_connections");
    }
  },
};
</script>

<style scoped>
html {
  font-size: 14px;
}
.v-input {
  font-size: 1em;
}
.v-label {
  font-size: 1em;
  height: 26px !important;
}
</style>
