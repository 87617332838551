// eslint-disable-next-line
import { applog } from "@/services/applog.js";
import axios from "axios";
import DOMPurify from "dompurify";
import { Preferences } from "@capacitor/preferences";

let dialog;

export class Office365Container {
  classname;

  constructor() {
    this.classname = "Office365Container";
    // Set up ItemChanged event
  }

  OpenExternalURL(url) {
    window.open(url);
  }

  setContext(connectionName, Entity, Entityid) {
    this.consolelog(
      "Office365Container setContext:" +
        connectionName +
        "/" +
        Entity +
        "/" +
        Entityid
    );
    // const payload = {
    //     connectionName,
    //     Entity,
    //     Entityid
    // }
    // window.vueAppInstance.$localStorage.set(
    //     "setContextData",
    //     payload
    //   );
    Preferences.set({ key: "activeTabName", value: connectionName });
    Preferences.set({ key: "Entity", value: Entity + "" });
    Preferences.set({ key: "Entityid", value: Entityid + "" });

    // window.vueAppInstance.$localStorage.set("Entity", Entity);
    // window.vueAppInstance.$localStorage.set("Entityid", Entityid);
  }

  consolelog(msg) {
    console.log(msg);
  }
  addEmailTo(email) {
    console.log("addEmailTo", email, window.Office);

    var item = window.Office.context.mailbox.item;
    item.to.setAsync(email);
  }

  async popupCenter(urlname, url, _datastring, params) {
    this.consolelog("Office365Container popupCenter:" + urlname);
    this.consolelog("url:" + url);
    this.consolelog(urlname, url, _datastring, params);
    let realurl = "";
    var __url = new String(url);
    if (urlname == "task") {
      //url should be
      //https://localhost:3000/#/task/SageCRM/company/205
      //but comes in as
      //https://localhost:3000/?et=#/mytaskpane/outlook365/explorer/#/task/SageCRM/company/205
      var __urlarray2 = __url.split(urlname + "/");
      var _urlpart12 = __urlarray2[0].split("?");
      realurl = _urlpart12[0] + "#/" + urlname + "/" + __urlarray2[1];
      this.consolelog("realurl task:" + realurl);
    } else {
      //we need to fix up ...url
      //example url is
      //https://localhost:3000/?et=#/mytaskpane/outlook365/explorer/#/fileemail/read/false/SageCRM/person/1779
      //we need
      //https://localhost:3000/#/fileemail/read/false/SageCRM/person/1779
      //so lets do it...
      var __urlarray = __url.split(urlname);
      var _urlpart1 = __urlarray[0].split("?");
      realurl = _urlpart1[0] + "#/" + urlname + __urlarray[1];
      console.log("realurl fileemail:" + realurl);
      let _EmailData = null;

      if (window.Office.context.mailbox) {
        // eslint-disable-next-line
        var item = window.Office.context.mailbox.item;

        _EmailData = await this.__item365toOurEmailObject(item);
        console.log("windowoffic-item:", _EmailData);
        await this.__storeLastViewedItem(_EmailData, "popupCenter");
        await this.__getBodyText(item);
        console.log("getbodytext end");
      } else {
        this.consolelog("getEmailData Office365Container: Office not found #1");
        //get from local storage
        var itemAsString = await window.vueAppInstance.$localStorage.get(
          "Office.context.mailbox.item"
        );
        //var itemAsString=(await Preferences.get({key:'Office.context.mailbox.item'})).value;
        // _EmailData=JSON.parse(itemAsString);
        _EmailData = itemAsString;
      }
    }
    var _that = this;
    // eslint-disable-next-line
    console.log(
      "window.Office.context.ui",
      JSON.stringify(window.Office.context.ui, function (key, value) {
        if (typeof value === "function") {
          return value.toString();
        } else {
          return value;
        }
      })
    );
    var contextUi = JSON.stringify(
      window.Office.context.ui,
      function (key, value) {
        if (typeof value === "function") {
          return value.toString();
        } else {
          return value;
        }
      }
    );

    // eslint-disable-next-line
    Preferences.set({ key: "window.Office.context.ui", value: contextUi });
    // eslint-disable-next-line
    window.Office.context.ui.displayDialogAsync(
      realurl,
      function (asyncResult) {
        console.log("bbbbb", window.Office.EventType.DialogMessageReceived);
        dialog = asyncResult.value;
        dialog.addEventHandler(
          window.Office.EventType.DialogMessageReceived,
          _that.processMessage
        );
        dialog.addEventHandler(
          window.Office.EventType.DialogEventReceived,
          _that.dialogClosed
        );
      },
      { height: 50, width: 60, displayInIframe: true, promptBeforeOpen: false }
    );
  }

  //  callback(result) {
  //     if (result.error) {
  //         console.log(result.error);
  //     } else {
  //         console.log("Attachment added");
  //     }
  //   }
  dialogClosed() {
    console.log("eee");
    dialog = null;
  }
  async processMessage(arg) {
    console.log("processMessage");
    var messageFromDialog = JSON.parse(arg.message);
    console.log("processMessage messageType:" + messageFromDialog.messageType);
    if (messageFromDialog.messageType === "selectHistoryEntity") {
      console.log("selectHistoryEntity:" + arg.message);
      window.vueAppInstance.processMessageQ();
    } else if (messageFromDialog.messageType === "parseEmail") {
      console.log("parseEmail:" + arg.message);
      window.vueAppInstance.processMessageQ();
    } else if (messageFromDialog.messageType === "updateProps") {
      let _payload = messageFromDialog.payload;
      console.log(
        "processMessage Office365container updateprops:" +
          JSON.stringify(_payload)
      );
      _customPropertiesweSet = _payload.data.customproperties;
      // eslint-disable-next-line
      Office.context.mailbox.item.loadCustomPropertiesAsync(
        customPropsCallback
      ); ///customPropsCallback uses _customPropertiesweSet
    } else if (messageFromDialog.messageType === "attachDocuments") {
      let _payload = JSON.parse(messageFromDialog.payload);
      var attachmentURL = _payload.link;
      var attachmentName = _payload.filename;
      console.log(
        "processMessage Office365container attachDocuments:",
        _payload
      );
      console.log(
        "processMessage Office365container attachmentURL:",
        attachmentURL
      );
      console.log(
        "processMessage Office365container attachmentName:",
        attachmentName
      );

      let connections = (await Preferences.get({ key: "connections" })).value;
      let connectionsData = JSON.parse(connections);
      var connectionClass = connectionsData[0].connectionClass;
      if (
        connectionClass == "MyTaskPaneEntityDataSageCRMAcceleratorPortalClass"
      ) {
        let authobject = (
          await Preferences.get({
            key: connectionsData[0].tabName + "_authobject",
          })
        ).value;
        let authobjectData = JSON.parse(authobject);
        console.log("365-authobjectData", authobjectData);

        //add token on only acplus case
        attachmentURL += "&token=" + authobjectData.sid;
      }

      console.log("365-attachmentURL", attachmentURL);
      // eslint-disable-next-line
      Office.context.mailbox.item.addFileAttachmentAsync(
        attachmentURL,
        attachmentName,
        { asyncContext: null },
        function (asyncResult) {
          // eslint-disable-next-line
          if (asyncResult.status === Office.AsyncResultStatus.Failed) {
            console.log("attach error-", asyncResult.error.message);
          } else {
            var attachmentID = asyncResult.value;
            console.log("ID of added attachment: " + attachmentID);
          }
        }
      );
      // dialog.close()
      // dialog = null;
    } else if (messageFromDialog.messageType === "fileUpload") {
      let _payload = messageFromDialog.payload;
      console.log("fileUpload _payload:" + JSON.stringify(_payload));
      // eslint-disable-next-line
      var _item = Office.context.mailbox
        ? Office.context.mailbox.item
        : window.vueAppInstance.$localStorage.get(
            "Office.context.mailbox.item"
          );
      //var _item = window.Office.context.mailbox ? window.Office.context.mailbox .item : (await Storage.get({key:'Office.context.mailbox.item'})).value
      console.log("item---", _item);
      _item.getAttachmentContentAsync(_payload.senderid, async (result) => {
        console.log("getAttachmentContentAsync result:" + result);
        console.log(JSON.stringify(result));

        // Parse string to be a url, an .eml file, a base64-encoded string, or an .icalendar file.
        // eslint-disable-next-line
        let __AttachmentContentFormat =
          window.Office.MailboxEnums.AttachmentContentFormat;
        //console.log(JSON.stringify(result));
        //result.value.content contains the value

        let connections = (await Preferences.get({ key: "connections" })).value;
        let connectionsData = JSON.parse(connections);
        var connectionClass = connectionsData[0].connectionClass;
        if (connectionClass == "MyTaskPaneEntityDataDataIslandsSage300Class") {
          console.log("MyTaskPaneEntityDataDataIslandsSage300Class-");
          const requestConfig = {
            headers: {
              "Content-Type": "application/json;charset=utf8",
            },
          };
          const token = (await Preferences.get({ key: "token" })).value;
          axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
          var payload = {
            Stream: result.value.content,
            FileName: _payload.name,
          };
          var uploadfileRes = await axios.post(
            _payload.link,
            JSON.stringify(JSON.stringify(payload)),
            requestConfig
          );
          console.log("uploadfileRes-", uploadfileRes);
        } else {
          switch (result.value.format) {
            case __AttachmentContentFormat.Base64: {
              // Handle file attachment as base64
              console.log("__handleAttachmentCallback Base64 START");
              //console.log(result.value.content);
              console.log("__handleAttachmentCallback Base64 END");
              console.log(
                "__handleAttachmentCallback _payload.type:" + _payload.type
              );
              console.log(
                "__handleAttachmentCallback _payload.link:" + _payload.link
              );

              //var blob= __b64toBlob("data:"+_payload.type+";base64,"+result.value.content, _payload.type);
              var blob = __b64toBlob(result.value.content, _payload.type);
              console.log("blob:", blob);

              var xfile = getbase64File(
                "data:" + _payload.type + ";base64," + result.value.content,
                _payload.name
              );
              console.log("xfile:", xfile);

              var formDataToUpload = new FormData();
              formDataToUpload.append("file", xfile);
              console.log(
                "formDataToUpload",
                formDataToUpload,
                JSON.stringify(formDataToUpload)
              );

              const headers = {
                "Content-Type": "multipart/form-data",
              };
              console.log(
                "__handleAttachmentCallback axios post",
                JSON.stringify(formDataToUpload)
              );
              /*
                            let ssarr=_payload.link.split("SID=");
                            _payload.link="http://localhost:1898/ac2020fupload.aspx?"+ssarr[1];
                            console.log(_payload.link);
                            */
              axios
                .post(_payload.link, formDataToUpload, {
                  headers: headers,
                })
                .then(function () {
                  console.log("Attachment SUCCESS!!");
                })
                .catch(function () {
                  console.log("Attachment FAILURE!!");
                });
              break;
            }
            case __AttachmentContentFormat.Eml:
              console.log("__handleAttachmentCallback Eml");
              // Handle email item attachment.
              break;
            case __AttachmentContentFormat.ICalendar:
              console.log("__handleAttachmentCallback ICalendar");
              // Handle .icalender attachment.
              break;
            case __AttachmentContentFormat.Url:
              console.log("__handleAttachmentCallback Url");
              // Handle cloud attachment.
              break;
            default:
              // Handle attachment formats that are not supported.
              this.consolelog("__handleAttachmentCallback Url");
          }
        }
      });
    } else if (messageFromDialog.messageType === "dialogClosed") {
      console.log("dialogClosed-type11", messageFromDialog);
      dialog.close();
      dialog = null;
    }
  }

  replytagemail(dataAsString) {
    console.log("outlook365-replytagemail", dataAsString);
    console.log("window-", window);
    window.external.replytagemail(dataAsString);
    this.consolelog(dataAsString);
  }

  getDocuments(dataAsString) {
    this.consolelog(dataAsString);
  }

  removeTag(tagPrefixSuffix) {
    console.log("outlook365-removetag", tagPrefixSuffix);
  }

  tagEmail(dataAsString) {
    console.log("outlook365-tagemail", dataAsString);
    console.log(
      "outlook365-tagemail",
      window.Office.context.mailbox.item.categories
    );

    // const masterCategoriesToAdd = [
    //     {
    //       displayName: "TestCategory",
    //       color: window.Office.MailboxEnums.CategoryColor.Preset0
    //     }
    //   ];
    window.Office.context.mailbox.item.categories.addAsync(
      "ddd",
      function (asyncResult) {
        if (asyncResult.status === window.Office.AsyncResultStatus.Succeeded) {
          console.log(`Successfully assigned category ' to item.`);
        } else {
          console.log(
            "categories.addAsync call failed with error: " +
              asyncResult.error.message
          );
        }
      }
    );
  }

  newtask(dataAsString) {
    this.consolelog(dataAsString);
  }

  newappt(dataAsString) {
    this.consolelog(dataAsString);
  }

  newemail(dataAsString) {
    this.consolelog(dataAsString);
  }

  setInstanceSettings(connectionName, instanceSettingsAsString) {
    this.consolelog(connectionName + "=" + instanceSettingsAsString);
  }

  SelectionChange() {
    console.log("Office365Container selectionchange");
  }

  // Write to a div with id='message' on the page.

  write(message) {
    document.getElementById("message").innerText += message;
  }

  // setSubject() {
  //     var today = new Date();
  //     var subject;
  //     var item;

  //     // Customize the subject with today's date.
  //     subject = 'Summary for ' + today.toLocaleDateString();

  //     item.subject.setAsync(
  //         subject,
  //         { asyncContext: { var1: 1, var2: 2 } },
  //         function (asyncResult) {
  //             if (asyncResult.status == window.Office.AsyncResultStatus.Failed){
  //                 write(asyncResult.error.message);
  //             }
  //             else {
  //                 // Successfully set the subject.
  //                 // Do whatever appropriate for your scenario
  //                 // using the arguments var1 and var2 as applicable.
  //             }
  //         });
  // }

  messageParent(jsonMessage) {
    var officeUi = window.Office.context.ui;
    console.log("window.Office", window.Office);
    if (window.Office) {
      window.Office.context.ui.messageParent(jsonMessage);
    } else {
      if (officeUi !== null && officeUi !== undefined) {
        officeUi.messageParent(jsonMessage);
      } else {
        window.external.MessageParent(jsonMessage);
      }
    }
  }
  buttonAction(senderDialog, dialogAction, senderData) {
    this.consolelog(
      "buttonAction:" + senderDialog + "=" + dialogAction + "=" + senderData
    );
    var messageObject;
    var jsonMessage;
    // eslint-disable-next-line
    //ref: https://docs.microsoft.com/en-us/office/dev/add-ins/develop/dialog-api-in-office-add-ins

    if (senderDialog == "History") {
      messageObject = {
        messageType: "selectHistoryEntity",
        payload: senderData,
      };
      jsonMessage = JSON.stringify(messageObject);
      // window.Office.context.ui.messageParent(jsonMessage);
      this.messageParent(jsonMessage);
    } else if (senderDialog == "ParseEmail") {
      messageObject = { messageType: "parseEmail", payload: senderData };
      jsonMessage = JSON.stringify(messageObject);
      this.messageParent(jsonMessage);
    } else if (senderDialog == "EmailTemplates") {
      this.consolelog("email template insert");
      messageObject = { messageType: "emailTemplate", payload: senderData };
      jsonMessage = JSON.stringify(messageObject);
      this.messageParent(jsonMessage);
    } else if (senderDialog == "AttachDocuments") {
      messageObject = { messageType: "attachDocuments", payload: senderData };
      jsonMessage = JSON.stringify(messageObject);
      this.messageParent(jsonMessage);
    } else if (senderDialog == "AddressBook") {
      messageObject = { messageType: "addressBook", payload: senderData };
      jsonMessage = JSON.stringify(messageObject);
      this.messageParent(jsonMessage);
    } else if (senderDialog == "FileEmail") {
      messageObject = { messageType: "fileUpload", payload: senderData };
      // console.log("FileEmail112", JSON.stringify(messageObject))
      // // jsonMessage.message = JSON.stringify(messageObject);
      // var tempObj = JSON.stringify(messageObject);
      // jsonMessage = {
      //     message:tempObj
      // }
      // console.log("ddd", window.localStorage.getItem('officeContext'))
      // console.log("eeee", window.Office.MailboxEnums)
      // this.processMessage(jsonMessage)
    }

    if (dialogAction == "close" || dialogAction == "ok") {
      messageObject = { messageType: "dialogClosed" };
      if (senderDialog == "EmailTemplates") {
        window.vueAppInstance.$localStorage.remove("Entity");
        window.vueAppInstance.$localStorage.remove("Entityid");
      }
      jsonMessage = JSON.stringify(messageObject);
      console.log("dialogClosed", jsonMessage);

      this.messageParent(jsonMessage);
    }
  }

  callback(asyncResult) {
    var arrayOfToRecipients = asyncResult.value;
    console.log(arrayOfToRecipients);
  }

  // selectHistoryEntity(entity){
  //     let dataAsObject=JSON.parse(entity);
  //     var messageObject = {messageType: "selectHistoryEntity", payload:dataAsObject};
  //     var jsonMessage = JSON.stringify(messageObject);
  //     window.Office.context.ui.messageParent(jsonMessage);
  // }

  fileUpload(dataAsString) {
    this.consolelog("Office365Container fileUpload1");
    this.consolelog(dataAsString);
    let dataAsObject = JSON.parse(dataAsString);
    var messageObject = { messageType: "fileUpload", payload: dataAsObject };
    var jsonMessage = JSON.stringify(messageObject);
    window.Office.context.ui.messageParent(jsonMessage);
    this.consolelog("Office365Container fileUpload1 messageParent"); //in this case it is the taskpane..see processMessage
  }

  async getEmailData(sender) {
    // Get a reference to the current message
    console.log("window.Office", window.Office);
    this.consolelog("getEmailData Office365Container:" + sender);
    var item = null;
    let _EmailData = null;
    // eslint-disable-next-line
    if (sender == "outlook365compose") {
      // _EmailData= JSON.parse(window.localStorage.getItem("promptEmailData"))
      _EmailData = JSON.parse(
        (await Preferences.get({ key: "promptEmailData" })).value
      );

      //    console.log("prompt_emaildata", _EmailData)
    } else if (
      window.Office &&
      window.Office.context &&
      window.Office.context.mailbox &&
      window.Office.context.mailbox.item
    ) {
      this.consolelog("getEmailData Office365Container: Found office #2");
      // eslint-disable-next-line
      item = window.Office.context.mailbox.item;
      console.log("__item", item);
      _EmailData = await this.__item365toOurEmailObject(item);
      console.log("_EmailData:", _EmailData);
      await this.__getBodyText(item);
    } else {
      this.consolelog("getEmailData Office365Container: Office not found #2");
      //get from local storage
      var itemAsString = await window.vueAppInstance.$localStorage.get(
        "Office.context.mailbox.item"
      );
      //var itemAsString=(await Preferences.get({key:'Office.context.mailbox.item'})).value;
      console.log("email as string", itemAsString);
      // _EmailData=JSON.parse(itemAsString);
      _EmailData = itemAsString;
    }
    console.log("_EmailData", _EmailData);
    return _EmailData; ///need to fix so its like our object
  }
  updateProps(dataAsString) {
    this.consolelog("Office365Container updateProps");
    this.consolelog(dataAsString);
    let dataAsObject = JSON.parse(dataAsString);
    var messageObject = { messageType: "updateProps", payload: dataAsObject };
    // console.log("update props:", Office.context.mailbox.item)
    var jsonMessage = JSON.stringify(messageObject);
    // window.Office.context.ui.messageParent(jsonMessage);
    if (window.Office) {
      window.Office.context.ui.messageParent(jsonMessage);
    } else {
      window.external.MessageParent(jsonMessage);
    }
    this.consolelog("Office365Container updateProps messageParent"); //in this case it is the taskpane..see processMessage
  }

  async __storeLastViewedItem(_EmailData, sender) {
    this.consolelog("store item start:" + sender);
    this.consolelog("store item _EmailData:" + JSON.stringify(_EmailData));
    await window.vueAppInstance.$localStorage.set(
      "Office.context.mailbox.item",
      JSON.stringify(_EmailData)
    );
    // Preferences.set({key:'Office.context.mailbox.item', value: JSON.stringify(_EmailData)});
    this.consolelog("store item complete:");
  }
  async __retrieveLastViewedItem() {
    this.consolelog("retrieve item start NEW:");
    let emailAsString = await window.vueAppInstance.$localStorage.get(
      "Office.context.mailbox.item"
    );
    this.consolelog("retrieve item start emailAsString:" + emailAsString);
    let res = JSON.parse(emailAsString);
    this.consolelog("retrieve item complete NEW:");
    return res;
  }
  async __retrieveLastViewedItemBroken() {
    this.consolelog("retrieve item start:");
    // let emailAsString= window.vueAppInstance.$localStorage.get('Office.context.mailbox.item');
    let emailAsString = (
      await Preferences.get({ key: "Office.context.mailbox.item" })
    ).value;
    let res = JSON.parse(emailAsString);
    this.consolelog("retrieve item complete:");
    return res;
  }
  __item365toOurEmailObject(item) {
    //build our email object
    console.log("__item365toOurEmailObject", item);
    let _EmailData = {
      from: {
        displayName: item.from.displayName,
        emailAddress: item.from.emailAddress,
        type: item.from.recipientType,
      },
      replyto: {
        displayName: item.from.displayName,
        emailAddress: item.from.emailAddress,
        type: item.from.recipientType,
      },
      fullName: item.from.displayName,
      phoneNumbers: [],
      to: this._parse365Recipients(item.to),
      cc: this._parse365Recipients(item.cc),
      bcc: this._parse365Recipients(item.bcc),
      subject: item.subject,
      body: "xxx",
      htmlBody: "...",
      attachments: this._parse365Attachments(item, false),
      inlineattachments: this._parse365Attachments(item, true),
      entryid: item.itemId,
      urls: [],
      addresses: [],
      sentItem: this._isSentItem(item),
      receivedDateTime: this._parse365SentDates(item),
      sentDateTime: this._parse365SentDates(item),
      companies: "",
    };
    return _EmailData;
  }

  // Helper function to get item body asynchronously
  getItemBodyAsync(item, contentType) {
    return new Promise(function (resolve, reject) {
      item.body.getAsync(contentType, function (result) {
        if (result.status === "succeeded") {
          resolve(result);
        } else {
          reject(new Error("Failed to get item body."));
        }
      });
    });
  }
  getAttachmentContentAsync(item, attid) {
    return new Promise(function (resolve, reject) {
      let __AttachmentContentFormat =
        window.Office.MailboxEnums.AttachmentContentFormat;
      item.getAttachmentContentAsync(attid, async (result) => {
        //console.log("getAttachmentContentAsync:"+JSON.stringify(result.value));
        if (result.status === "succeeded") {
          switch (result.value.format) {
            case __AttachmentContentFormat.Base64:
              //console.log("base 64 image:"+__src);
              tmp.innerHTML = tmp.innerHTML.replace(
                ' src="' + __src,
                'src="data:' + __att.type + ";base64," + result.value.content
              );
              _EmailData.htmlBody = tmp.innerHTML;
              console.log("_EmailData.attachment index-", xx, "-");
              console.log(
                "_EmailData.attachments:",
                _EmailData.attachments.length
              );

              _EmailData.attachments.splice(xx - removedCount, 1);
              removedCount += 1;
              console.log(
                "_EmailData.attachments:" + _EmailData.attachments.length,
                removedCount
              );
              await _that.__storeLastViewedItem(_EmailData, "__getBodyText #3");
              console.log("__storeLastViewedItem #9");
              break;
            default:
              // Handle attachment formats that are not supported.
              console.log("__handleAttachmentCallback no match on format");
          }
          resolve();
        } else {
          reject(new Error("Failed to get item body."));
        }
      });
    });
  }
  async __getBodyText(item) {
    var attachments = Office.context.mailbox.item.attachments;
    console.log("__getBodyText 365", attachments);
    var _that = this;

    try {
      // Get the text body
      let textResult = await this.getItemBodyAsync(item, "text");
      let textBody = textResult.value.replace(/\n\n\n\n/, "");

      // Store the updated text body
      let _EmailData = await _that.__retrieveLastViewedItem();
      _EmailData.body = textBody;
      await _that.__storeLastViewedItem(_EmailData, "__getBodyText");

      // Get the HTML body
      let htmlResult = await this.getItemBodyAsync(item, "html");
      let htmlBody = htmlResult.value;
      console.log("365 Email Body Retrieved");

      // Store the original email data
      _EmailData = await _that.__retrieveLastViewedItem();
      _EmailData.htmlBody = htmlBody;
      await _that.__storeLastViewedItem(_EmailData, "__getBodyText #2");

      // Fix up any images
      let tmp_EmailData = JSON.stringify(_EmailData);
      let tmp_EmailDataObj = JSON.parse(tmp_EmailData);

      // eslint-disable-next-line
      let __AttachmentContentFormat =
        window.Office.MailboxEnums.AttachmentContentFormat;

      if (_EmailData.htmlBody != null) {
        //loop through the embedded attachments
        var tmp = document.createElement("div");
        tmp.innerHTML = _EmailData.htmlBody;
        var srcImgs = tmp.querySelectorAll("img");
        console.log("srcImgs:" + srcImgs.length);
        let removedCount = 0;
        // If email is replied or forwared email, there are same inline images that have same name, so need to remove only one from the attachments list
        let inlinedImages = [];
        srcImgs.forEach(async function (srcimg) {
          let __src = srcimg.getAttribute("src");
          console.log("__src:" + __src, "-", srcimg);
          console.log("inlinedImages:", inlinedImages);
          console.log(__src.indexOf("cid:"));
          if (
            __src.indexOf("cid:") == 0 &&
            tmp_EmailDataObj.inlineattachments
          ) {
            // tmp_EmailDataObj.attachments =  tmp_EmailDataObj.attachments.filter(obj => obj.isInline !== true );
            console.log("tmp_EmailDataObj", tmp_EmailDataObj);
            for (
              let xx = 0;
              xx < tmp_EmailDataObj.inlineattachments.length;
              xx++
            ) {
              let __att = tmp_EmailDataObj.inlineattachments[xx];
              console.log(
                ' __att.type.indexOf("image/") ',
                inlinedImages.includes(__att.filename)
              );
              if (__att.isInline == true && __src.indexOf(__att.caption) > 0) {
                //tmp.innerHTML=tmp.innerHTML.replace(__src,"test.png");

                console.log("__att.id:" + __att.id);
                console.log("__att.index:" + xx, __att);
                // _that.removeAttachment(__att.id);
                // eslint-disable-next-line
                await new Promise(function (resolve, reject) {
                  Office.context.mailbox.item.getAttachmentContentAsync(
                    __att.id,
                    async (result) => {
                      console.log(
                        "getAttachmentContentAsync:" + JSON.stringify(result)
                      );
                      if (result.status === "succeeded") {
                        switch (result.value.format) {
                          case __AttachmentContentFormat.Base64:
                            //console.log("base 64 image:"+__src);
                            tmp.innerHTML = tmp.innerHTML.replace(
                              ' src="' + __src,
                              'src="data:' +
                                __att.type +
                                ";base64," +
                                result.value.content
                            );
                            _EmailData.htmlBody = tmp.innerHTML;
                            console.log(
                              "_EmailData.attachment index-",
                              xx,
                              "-"
                            );
                            console.log(
                              "_EmailData.attachments:",
                              _EmailData.attachments.length
                            );
                            // if (!inlinedImages.includes(__att.filename))
                            //   _EmailData.attachments.splice(
                            //     xx - removedCount,
                            //     1
                            //   );
                            inlinedImages.push(__att.filename);
                            removedCount += 1;
                            console.log(
                              "_EmailData.attachments:" +
                                _EmailData.attachments.length,
                              removedCount
                            );
                            await _that.__storeLastViewedItem(
                              _EmailData,
                              "__getBodyText #3"
                            );
                            console.log("__storeLastViewedItem #9");
                            break;
                          default:
                            // Handle attachment formats that are not supported.
                            console.log(
                              "__handleAttachmentCallback no match on format"
                            );
                        }
                        resolve();
                      } else {
                        reject(
                          new Error("Failed to get item attachment with id.")
                        );
                      }
                    }
                  );
                });
                // await _that.getAttachmentContentAsync(
                //   Office.context.mailbox.item,
                //   __att.id
                // );
                console.log("individual-attahcment", JSON.stringify(__att));
              }
            }
          }
        });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }

  removeAttachment(attachmentId) {
    // When the attachment is removed, the callback function is invoked.
    // Here, the callback function uses an asyncResult parameter and
    // gets the ID of the removed attachment if the removal succeeds.
    // You can optionally pass any object you wish to access in the
    // callback function as an argument to the asyncContext parameter.
    Office.context.mailbox.item.removeAttachmentAsync(
      attachmentId,
      { asyncContext: null },
      function (asyncResult) {
        if (asyncResult.status === Office.AsyncResultStatus.Failed) {
          write(asyncResult.error.message);
        } else {
          write("Removed attachment with the ID: " + asyncResult.value);
        }
      }
    );
  }
  _parse365SentDates(_Mailitem) {
    let _date = null;
    if (_Mailitem.dateTimeCreated) {
      _date = _Mailitem.dateTimeCreated;
      console.log("date---", _date);
    } else {
      _date = new Date();
    }

    const date = window.Office.context.mailbox.convertToLocalClientTime(_date);
    console.log("date1---", date);
    console.log("date2---", _date.toLocaleString());

    let res = {
      year: _date.getFullYear(),
      month: _date.getMonth() + 1,
      day: _date.getDate(),
      hour: _date.getHours(),
      minute: _date.getMinutes(),
      second: _date.getSeconds(),
      raw: _date.toLocaleString(),
      rawutc: _date.getUTCDate(),
    };

    //   let res={
    //     "year":date.year,
    //     "month":parseInt(date.month+1),
    //     "day":date.date,
    //     "hour":date.hours,
    //     "minute":date.minutes,
    //     "second":date.seconds,
    //     "raw": parseInt(date.month+1) + "/" + date.date + "/" + date.year + ", " + date.hours + ":" + date.minutes + ":"+ date.seconds +" "+ (date.hours >= 12 ? "PM":"AM"),
    //     "rawutc":parseInt(date.month+1) + "/" + date.date + "/" +  date.year + ", " + date.hours + ":" + date.minutes + ":"+ date.seconds +" "+ (date.hours >= 12 ? "PM":"AM")
    //   }
    console.log("date-res1---", res);
    return res;
  }

  _parse365Attachments(item, isInline) {
    console.log("_parse365Attachments");
    let res = [];
    let i = 0;
    console.log("_parse365Attachments length:", item);
    if (item.attachments) {
      if (item.attachments.length > 0) {
        for (i = 0; i < item.attachments.length; i++) {
          var attachment = item.attachments[i];
          if (attachment.isInline === isInline) {
            console.log(
              "_parse365Attachments attachment:" + JSON.stringify(attachment)
            );
            var _att = {
              id: attachment.id,
              name: attachment.name,
              caption: attachment.name,
              size: attachment.size,
              filename: attachment.name,
              type: attachment.contentType,
              base64: "",
              isInline: attachment.isInline,
              value: true,
            };
            res.push(_att);
          }
        }
      }
    } else {
      res = {};
    }
    return res;
  }

  _parse365Recipients(recObj) {
    let rec_res = [];
    for (var ff = 0; ff < recObj.length; ff++) {
      let _rec = {
        displayName: recObj[ff].displayName,
        emailAddress: recObj[ff].emailAddress,
        type: recObj[ff].recipientType,
      };
      rec_res.push(_rec);
    }
    return rec_res;
  }

  _isSentItem(item) {
    this.consolelog("_isSentItem");
    if (item.sender) {
      // eslint-disable-next-line
      if (
        Office &&
        Office.context &&
        Office.context.mailbox &&
        Office.context.mailbox.userProfile
      ) {
        // eslint-disable-next-line
        this.consolelog(
          "isSentItem userProfile:" +
            Office.context.mailbox.userProfile.emailAddress
        );
        // eslint-disable-next-line
        this.consolelog("isSentItem sender:" + item.sender.emailAddress);
        // eslint-disable-next-line
        return (
          Office.context.mailbox.userProfile.emailAddress ===
          item.sender.emailAddress
        );
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
}

/**
 * https://ourcodeworld.com/articles/read/322/how-to-convert-a-base64-image-into-a-image-file-and-upload-it-with-an-asynchronous-form-using-jquery
 * Convert a base64 string in a Blob according to the data and contentType.
 *
 * @param b64Data {String} Pure base64 string without contentType
 * @param contentType {String} the content type of the file i.e (image/jpeg - image/png - text/plain)
 * @param sliceSize {Int} SliceSize to process the byteCharacters
 * @see http://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
 * @return Blob
 */
function __b64toBlob(b64Data, contentType, sliceSize) {
  contentType = contentType || "";
  sliceSize = sliceSize || 1024;
  var byteCharacters = atob(b64Data);
  var byteArrays = [];
  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);
    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  var blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

function getbase64File(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}
// eslint-disable-next-line
var _customProps;
// eslint-disable-next-line
var _customPropertiesweSet;
function customPropsCallback(asyncResult) {
  console.log("customPropsCallback");
  if (asyncResult.status == window.Office.AsyncResultStatus.Failed) {
    // Handle the failure.
  } else {
    // Successfully loaded custom properties,
    // can get them from the asyncResult argument.
    _customProps = asyncResult.value;
    if (_customPropertiesweSet.length > 0) {
      for (var q = 0; q < _customPropertiesweSet.length; q++) {
        var __prop = _customPropertiesweSet[q];
        // eslint-disable-next-line
        vueAppInstance.$applog("__prop:" + __prop.name + "=" + __prop.value);
        _customProps.set(__prop.name, __prop.value);
      }
      console.log("saving custom properties");
      // Save all custom properties to server.
      _customProps.saveAsync(saveCallback_CustomProperties);
    }
    _customPropertiesweSet = null;
  }
}

// Callback function from saving custom properties.
function saveCallback_CustomProperties(asyncResult) {
  console.log("saveCallback_CustomProperties:" + JSON.stringify(asyncResult));
  if (asyncResult.status == window.Office.AsyncResultStatus.Failed) {
    // Handle the failure.
    console.log("saveCallback_CustomProperties failed");
    // eslint-disable-next-line
    vueAppInstance.$applog("saveCallback_CustomProperties failed");
  }
}
function setCallback(asyncResult) {
  if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
    console.log("Successfully set headers");
  } else {
    console.log("Error setting headers: " + JSON.stringify(asyncResult.error));
  }
}
function getCallback(asyncResult) {
  if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
    console.log("Sender's preferred fruit: " + JSON.stringify(asyncResult));
  } else {
    console.log(
      "Error getting preferences from header: " +
        JSON.stringify(asyncResult.error)
    );
  }
}
