// eslint-disable-next-line
import {applog} from '@/services/applog.js'
import { Preferences } from "@capacitor/preferences";
// eslint-disable-next-line
import axios from 'axios';

//this is our outlook app
export class OutlookAppContainerWebview {

    classname;

    constructor() {
        this.classname='OutlookAppContainerWebview';
    }

    OpenExternalURL(url)
    {
        // eslint-disable-next-line
        window.chrome.webview.hostObjects.sync.TS.OpenExternalURL(url);
    }
    setContext(connectionName, Entity, Entityid)
    {
        this.consolelog("OutlookAppContainerWebview setContext:"+connectionName+"/"+Entity+"/"+Entityid);
        try{
            // eslint-disable-next-line
            window.chrome.webview.hostObjects.sync.TS.setContext(connectionName,Entity,Entityid);
        }catch(e){
            this.consolelog("ERROR OutlookAppContainerWebview setContext:"+e.message);
        }
    }
    consolelog(msg)
    {
        // eslint-disable-next-line
        window.chrome.webview.hostObjects.sync.TS.consolelog(msg);
    }

    popupCenter(urlname, url, _datastring, params)
    {
        this.consolelog("popupCenter/outlookappcontainerwebview:"+urlname);
        window.chrome.webview.hostObjects.sync.TS.popupCenter(urlname, url, _datastring, params);
    }

    replytagemail(dataAsString)
    {
        // eslint-disable-next-line
        window.chrome.webview.hostObjects.sync.TS.replytagemail(dataAsString);
    }

    getDocuments(dataAsString)
    {
        // eslint-disable-next-line
        window.chrome.webview.hostObjects.sync.TS.getDocuments(dataAsString);
    }

    removeTag(tagPrefixSuffix)
    {
        // eslint-disable-next-line
        window.chrome.webview.hostObjects.sync.TS.removeTag(tagPrefixSuffix);
    }

    tagEmail(dataAsString)
    {
        // eslint-disable-next-line
        window.chrome.webview.hostObjects.sync.TS.tagEmail(dataAsString);
    }

    newtask(dataAsString)
    {
        // eslint-disable-next-line
        window.chrome.webview.hostObjects.sync.TS.newtask(dataAsString);
    }

    newappt(dataAsString)
    {
        // eslint-disable-next-line
        window.chrome.webview.hostObjects.sync.TS.newappt(dataAsString);
    }

    newemail(dataAsString)
    {
        // eslint-disable-next-line
        window.chrome.webview.hostObjects.sync.TS.newemail(dataAsString);
    }

    setInstanceSettings(connectionName,instanceSettingsAsString)
    {
        console.log('setInstanceSettings webview2 #1 of 2');
        console.log('setInstanceSettings webview2 #1 of 2 connectionName:'+connectionName);
        console.log('setInstanceSettings webview2 #1 of 2 instanceSettingsAsString:'+instanceSettingsAsString);
        // eslint-disable-next-line
        window.chrome.webview.hostObjects.sync.TS.setInstanceSettings(connectionName,instanceSettingsAsString);
        console.log('setInstanceSettings webview2 #2 of 2');
    }

    addEmailTo(valEmailAddress)
    {
        console.log("webview-email", valEmailAddress, window.Office);
        // eslint-disable-next-line
        window.chrome.webview.hostObjects.sync.TS.addEmailTo(valEmailAddress);

    }

    SelectionChange()
    {
        // eslint-disable-next-line
        window.chrome.webview.hostObjects.sync.TS.SelectionChange();
    }

    buttonAction(senderDialog,dialogAction,senderData)
    {
        console.log('buttonAction webview,'+senderDialog+','+dialogAction+','+senderData);
        // eslint-disable-next-line
        window.chrome.webview.hostObjects.sync.TS.buttonAction(senderDialog,dialogAction,senderData);
    }

    async fileUpload(dataAsString)
    {
        console.log('buttonAction fileUpload,'+dataAsString);
        let connections= (await Preferences.get({key:'connections'})).value;
        let connectionsData=JSON.parse(connections);
        var connectionClass = connectionsData[0].connectionClass;
        console.log("fileupload connectionClass", connectionClass)
        if(connectionClass == 'MyTaskPaneEntityDataDataIslandsSage300Class'){
            // console.log('MyTaskPaneEntityDataDataIslandsSage300Class-')
            // const requestConfig = {
            //     headers: {
            //     'Content-Type': 'application/json;charset=utf8',
            //     }
            // }
            // const  token = (await Preferences.get({ key: "token" })).value;
            // axios.defaults.headers.common = { Authorization: `Bearer ${token}` }
            // var payload = {
            //     "Stream": result.value.content,
            //     "FileName": _payload.name
            // }
            // var uploadfileRes = await axios.post(_payload.link,  JSON.stringify(JSON.stringify(payload)), requestConfig);
            // console.log('uploadfileRes-', uploadfileRes)
        } else{

            // eslint-disable-next-line
            window.chrome.webview.hostObjects.sync.TS.fileUpload(dataAsString);
        }
    }
    getEmailData(sender)
    {
        console.log('getEmailData webview sender:'+sender);
        // eslint-disable-next-line
        let res=null;
        try{
            res = window.chrome.webview.hostObjects.sync.TS.getEmailData(sender);
            console.log('getEmailData webview2 #A');
        }catch{
            res = window.chrome.webview.hostObjects.sync.TS.getEmailData();
            console.log('getEmailData webview2 #B');
        }
        console.log('getEmailData webview2 ');
        return res;
    }
    updateProps(dataAsString)
    {
        // eslint-disable-next-line
        window.chrome.webview.hostObjects.sync.TS.updateProps(dataAsString);
    }

}