// eslint-disable-next-line
import {applog} from '@/services/applog.js'


import { Preferences } from '@capacitor/preferences';

export class MobileAppContainer {

    classname;

    constructor() {
        this.classname='MobileAppContainer';
    }

    setContext(connectionName, Entity, Entityid)
    {
        this.consolelog("MobileAppContainer setContext:"+connectionName+"/"+Entity+"/"+Entityid);
        // const payload = {
        //     connectionName,
        //     Entity,
        //     Entityid
        // }
        // window.vueAppInstance.$localStorage.set(
        //     "setContextData",
        //     payload
        //   );
        Preferences.set({ key: "activeTabName", value: connectionName });
        Preferences.set({ key: "Entity", value: Entity+"" });
        Preferences.set({ key: "Entityid", value: Entityid+"" });

        // window.vueAppInstance.$localStorage.set("Entity", Entity);
        // window.vueAppInstance.$localStorage.set("Entityid", Entityid);
        
    }	

    OpenExternalURL(url)
    {
        window.open(url);
    }

    consolelog(msg)
    {
        console.log(msg);
    }

    async popupCenter(urlname, url, _datastring, params){
        this.consolelog("MobileAppContainer popupCenter:"+urlname);
        this.consolelog("url:"+url);
        this.consolelog(urlname, url, _datastring, params);
    }
    
    newtask(dataAsString){
        this.consolelog(dataAsString);
    }

    newappt(dataAsString){
        this.consolelog(dataAsString);
    }

    newemail(dataAsString){
        this.consolelog(dataAsString);
    }

    setInstanceSettings(connectionName,instanceSettingsAsString)
    {
        this.consolelog(connectionName+"="+instanceSettingsAsString);
    }

    buttonAction(senderDialog,dialogAction,senderData){
        this.consolelog("MobileAppContainer buttonAction:"+senderDialog+"="+dialogAction+"="+senderData);
    }

    fileUpload(dataAsString){
        this.consolelog("MobileAppContainer fileUpload");
        this.consolelog(dataAsString);
    }
  
}